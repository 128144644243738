/* eslint-disable no-bitwise */
<template>
  <div class="infomation-and-adress">
    <!--===============  Thông tin =================-->
    <custtom-title
      :icon="'InfoIcon'"
      :title="'Thông tin'"
    />
    <div class="infomation-and-adress__input__01">
      <b-row>
        <!--===============  Chức doanh =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="jobCenter"
          >
            <label for="jobCenter">Chức doanh <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="title"
              rules="required"
              :custom-messages="{required: `Chức doanh là bắt buộc`}"
            >
              <b-form-input
                v-model="dataWorker.title"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--===============  Vị trí công việc  =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label for="basicInput">Vị trí công việc <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="positionId"
              rules="required"
              :custom-messages="{required: `Vị trí công việc là bắt buộc`}"
            >
              <v-select
                v-model="dataWorker.positionId"
                :reduce="title => title.id"
                label="name"
                :options="arrPossition || []"
                :placeholder="'Lựa chọn vị trí công việc'"
                :state="errors.length > 0 ? false:null"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--===============  Địa điểm công việc=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label for="jobCenter">Địa điểm công việc</label>
            <b-form-input
              v-model="dataWorker.workingPlace"
            />
          </b-form-group>
        </b-col>
        <!--===============  Tuổi nghề  =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label>Tuổi nghề</label>
            <b-form-input
              id="basicInput"
              v-model="dataWorker.ageJob"
            />
          </b-form-group>
        </b-col>
        <!--===============  Bậc thợ  =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label>Bậc thợ</label>
            <b-form-input
              id="basicInput"
              v-model="dataWorker.levelJob"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <!-- Làm công tác an toàn, vệ sinh lao động -->
        <b-form-checkbox
          id="checkbox-1"
          v-model="dataWorker.isOSH"
          name="checkbox-1"
          class="mr-2 ml-1"
        >
          Làm công tác an toàn, vệ sinh lao động
        </b-form-checkbox>

        <!-- Làm công tác y tế -->
        <b-form-checkbox
          id="checkbox-2"
          v-model="dataWorker.isHealth"
          name="checkbox-2"
          class="mr-2"
        >
          Làm công tác y tế
        </b-form-checkbox>

        <!-- Người khuyết tật -->
        <b-form-checkbox
          id="checkbox-3"
          v-model="dataWorker.isDisabilities"
          name="checkbox-3"
        >
          Người khuyết tật
        </b-form-checkbox>
      </b-row>
    </div>
    <!--===============  Tiền lương =================-->
    <custtom-title
      :icon="'DollarSignIcon'"
      :title="'Tiền lương'"
    />
    <div class="infomation-and-adress__input__01">
      <b-row>
        <!--===============  Hệ số =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label for="jobCenter">Hệ số</label>
            <b-form-input
              v-model="dataWorker.salaryRatio"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!--===============  Mức lương  =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label for="jobCenter">Mức lương</label>
            <b-form-input
              v-model="dataWorker.salary"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!--=============== Phụ cấp chức vụ=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label for="jobCenter">Phụ cấp chức vụ</label>
            <b-form-input
              v-model="dataWorker.positionAllowance"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!--=============== Phụ cấp thâm niên vượt khung (%)=================-->
        <b-col
          md="4"
          xl="4"
          class="mt-1"
        >
          <b-form-group>
            <label for="jobCenter">Phụ cấp thâm niên vượt khung (%)</label>
            <b-form-input
              v-model="dataWorker.overtimeAllowance"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!--=============== Phụ cấp thâm niên nghề (%)=================-->
        <b-col
          md="4"
          xl="4"
          class="mt-1"
        >
          <b-form-group>
            <label for="jobCenter">Phụ cấp thâm niên nghề (%)</label>
            <b-form-input
              v-model="dataWorker.seniorityAllowance"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!--=============== Phụ cấp lương=================-->
        <b-col
          md="4"
          xl="4"
          class="mt-1"
        >
          <b-form-group>
            <label for="jobCenter">Phụ cấp lương</label>
            <b-form-input
              v-model="dataWorker.salaryAllowance"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!--=============== Các khoản bổ sung================-->
        <b-col
          md="4"
          xl="4"
          class="mt-1"
        >
          <b-form-group>
            <label for="jobCenter">Các khoản bổ sung</label>
            <b-form-input
              v-model="dataWorker.extras"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <!--===============  Ngành / Nghề nặng nhọc, độc hại =================-->
    <custtom-title
      :icon="'BriefcaseIcon'"
      :title="'Ngành / Nghề nặng nhọc, độc hại'"
    />
    <div class="infomation-and-adress__input__01">
      <b-row>
        <!--===============  Ngày bắt đầu =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label for="jobCenter">Ngày bắt đầu</label>
            <date-time-picker
              v-model="dataWorker.toxicStartString"
              :disabledInput="true"
            />
          </b-form-group>
        </b-col>
        <!--===============  Ngày kết thúc  =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label for="jobCenter">Ngày kết thúc</label>
            <date-time-picker
              v-model="dataWorker.toxicEndString"
              :disabledInput="true"
            />
          </b-form-group>
        </b-col>
        <!--===============  Làm việc trong điều kiện nặng nhọc =================-->
        <b-col
          md="4"
          xl="4"
          class="checkbox-style"
        >
          <b-form-checkbox
            id="checkbox-4"
            v-model="dataWorker.isToxic"
            name="checkbox-4"
          >
            Làm việc trong Điều kiện nặng nhọc, độc hại, nguy hiểm
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
    <!--===============  Hợp đồng lao động =================-->
    <custtom-title
      :icon="'FileTextIcon'"
      :title="'Hợp đồng lao động'"
    />
    <div class="infomation-and-adress__input__01">
      <b-row>
        <!--===============  Loại hợp đồng lao động =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label for="basicInput">Loại hợp đồng lao động <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="contractType"
              rules="required"
              :custom-messages="{required: `Loại hợp đồng lao động là bắt buộc`}"
            >
              <v-select
                v-model="dataWorker.contractType"
                :reduce="title => title.contractType"
                label="name"
                :options="contractType || []"
                :placeholder="'Lựa chọn loại hợp đồng lao động'"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--===============  Ngày bắt đầu  =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label for="jobCenter">Ngày bắt đầu</label>
            <date-time-picker
              v-model="dataWorker.contractStartString"
              :disabledInput="true"
            />
          </b-form-group>
        </b-col>
        <!--===============  Ngày kết thúc  =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label for="jobCenter">Ngày kết thúc</label>
            <date-time-picker
              v-model="dataWorker.contractEndString"
              :disabledInput="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <!--===============  Ngành / Nghề nặng nhọc, độc hại =================-->
    <custtom-title
      :icon="'ShieldIcon'"
      :title="'Đóng bảo hiểm xã hội'"
    />
    <div class="infomation-and-adress__input__01">
      <b-row>
        <!--===============  Ngày bắt đầu =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label for="jobCenter">Ngày bắt đầu</label>
            <date-time-picker
              v-model="dataWorker.socialInsuranceStartString"
              :disabledInput="true"
            />
          </b-form-group>
        </b-col>
        <!--===============  Ngày kết thúc  =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label for="jobCenter">Ngày kết thúc</label>
            <date-time-picker
              v-model="dataWorker.socialInsuranceEndString"
              :disabledInput="true"
            />
          </b-form-group>
        </b-col>
        <!--===============  Đóng bảo hiểm xã hội =================-->
        <b-col
          md="4"
          xl="4"
          class="checkbox-style"
        >
          <b-form-checkbox
            id="checkbox-5"
            v-model="dataWorker.isSocialInsurance"
            name="checkbox-5"
          >
            Đóng bảo hiểm xã hội
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BRow, BCol, BFormInput, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import CusttomTitle from '@/views/management-business/business/pages/components/InformationAndAdressTitle.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    CusttomTitle,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    DateTimePicker,
    BFormGroup,
  },
  props: {
    contractType: {
      type: Array,
      default: _ => [],
    },
    arrPossition: {
      type: Array,
      default: _ => [],
    },
  },
  data() {
    return {
      required,
      dataWorker: {
        title: null,
        positionId: null,
        workingPlace: null,
        salaryRatio: 0,
        salary: 0,
        positionAllowance: 0,
        overtimeAllowance: 0,
        seniorityAllowance: 0,
        salaryAllowance: 0,
        extras: 0,
        toxicStartString: null,
        toxicEndString: null,
        contractType: null,
        contractStartString: null,
        contractEndString: null,
        socialInsuranceStartString: null,
        socialInsuranceEndString: null,
        ageJob: '',
        levelJob: '',
        isOSH: false,
        isHealth: false,
        isToxic: false,
        isDisabilities: false,
        isSocialInsurance: false,

      },
    }
  },
  computed: {
    ...mapState('worker', ['dataEdit']),
  },
  watch: {
    dataEdit(val) {
      if (val && this.$route.params.id) {
        this.dataWorker = {
          title: this.dataEdit.title,
          positionId: this.dataEdit.positionId,
          workingPlace: this.dataEdit.workingPlace,
          salaryRatio: this.dataEdit.salaryRatio,
          salary: this.dataEdit.salary,
          positionAllowance: this.dataEdit.positionAllowance,
          overtimeAllowance: this.dataEdit.overtimeAllowance,
          seniorityAllowance: this.dataEdit.seniorityAllowance,
          salaryAllowance: this.dataEdit.salaryAllowance,
          extras: this.dataEdit.extras,
          toxicStartString: this.dataEdit.toxicStartString,
          toxicEndString: this.dataEdit.toxicEndString,
          contractStartString: this.dataEdit.contractStartString,
          contractEndString: this.dataEdit.contractEndString,
          socialInsuranceStartString: this.dataEdit.socialInsuranceStartString,
          socialInsuranceEndString: this.dataEdit.socialInsuranceEndString,
          ageJob: this.dataEdit.ageJob,
          levelJob: this.dataEdit.levelJob,
          isOSH: this.dataEdit.isOSH,
          isHealth: this.dataEdit.isHealth,
          isToxic: this.dataEdit.isToxic,
          isDisabilities: this.dataEdit.isDisabilities,
          isSocialInsurance: this.dataEdit.isSocialInsurance,
          contractType: this.dataEdit.contractType,
        }
        // this.getAllComboBox(this.dataEdit.countryId)
        // this.getComboBox(this.dataEdit.countryId)
      }
    },

    // async userTypeId(val) {
    //   if (val && this.userTypeId !== 0) {
    //     await this.getAllComboBox(this.userTypeId)
    //     await this.getComboBox(this.userTypeId)
    //     this.dataWorker.positionId = null
    //     this.dataWorker.contractType = null
    //   } else if (this.userTypeId === 0) {
    //     this.dataWorker.positionId = null
    //     this.dataWorker.contractType = null
    //     this.arrPossition = []
    //     this.contractType = []
    //   }
    // },
  },
  // created() {
  //   this.getAllComboBox(this.userTypeId)
  //   this.getComboBox(this.userTypeId)
  // },
  methods: {
    // eslint-disable-next-line consistent-return
    // async getAllComboBox(countryId) {
    //   const { data } = await axiosApiInstance.get('/Worker/get-combobox-position-worker', {
    //     params: { countryId },
    //   })
    //   this.arrPossition = data?.data
    // },
    // async getComboBox(countryId) {
    //   const { data } = await axiosApiInstance.get('/Worker/get-combobox-contract-type', {
    //     params: { countryId },
    //   })
    //   this.contractType = data?.data
    // },
  },
}
</script>

<style lang="scss">
.infomation-and-adress {
  padding: 20px;
}
.checkbox-style {
  margin-top: 40px;
}
</style>
