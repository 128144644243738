<template>
  <div>
    <!-- ===================================== ô input nhập yêu cầu ==================================-->
    <b-col
      md="4"
      xl="4"
      class="mb-1"
    >
      <div>
        <label for="textarea-default">Quá trình đào tạo</label>
        <b-form-textarea
          id="textarea-default"
          v-model="dataTraining.trainingProgress"
          placeholder=""
          rows="3"
          no-resize
        />
      </div>
    </b-col>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
  },
  data() {
    return {
      dataTraining: {
        trainingProgress: null,
      },
    }
  },
  computed: {
    ...mapState('worker', ['dataEdit']),
  },
  watch: {
    dataEdit(val) {
      if (val) {
        this.dataTraining = {
          trainingProgress: this.dataEdit.trainingProgress,
        }
      }
    },
  },
}
</script>

<style lang="scss">
</style>
